body {
  background-color: #231f20 !important;
}

html {
  background-color: #231f20 !important;
}

.splash {
  background-color:#231f20!important;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

nav {
    padding-top: .5em;
    padding-right: .5em;
    padding-left: .5em;
    background-color: #231f20;
}
 a.footlink {
    color: #231f20 !important;
 }

.Mutt_Button {
    background-color:#f0f0f0;
    border-radius:8px;
    display:inline-block;
    font-family: 'Roboto Mono', sans-serif;
    font-size: 1.7125em;
    text-transform: uppercase;
    font-weight: 500;
    cursor:pointer;
    color:#231f20 !important;
    font-size:1em;
    padding:16px 32px;
    text-decoration:none;
    margin: 0px 8px 8px 0px;
}


.Mutt_Button_Alt {
    background-color:#f0f0f0;
    border-radius:8px;
    display:block;
    width: 100%;
    font-family: 'Roboto Mono', sans-serif;
    font-size: 1.7125em;
    text-transform: uppercase;
    font-weight: 500;
    cursor:pointer;
    color:#231f20!important;
    font-size:1em;
    padding:16px 32px;
    text-decoration:none;
    margin: 0px 8px 8px 0px;
}

.Mutt_Button:hover {
    background-color:#f0f0f0;
    border-radius:8px;
    display:inline-block;
    font-family: 'Roboto Mono', sans-serif;
    font-size: 1.7125em;
    text-transform: uppercase;
    font-weight: 500;
    cursor:pointer;
    color:#231f20;
    font-size:1em;
    padding:16px 32px;
    text-decoration:none;
    margin: 0px 8px 8px 0px;
    text-decoration: none;
}

.Mutt_Button_Alt:hover  {
    background-color:#f0f0f0;
    border-radius:8px;
    display:block;
    width: 100%;
    font-family: 'Roboto Mono', sans-serif;
    font-size: 1.7125em;
    text-transform: uppercase;
    font-weight: 500;
    cursor:pointer;
    color:#231f20!important;
    font-size:1em;
    padding:16px 32px;
    text-decoration:none;
    margin: 0px 8px 8px 0px;
}

p {
    color: #f0f0f0;
    size: 1.25em;
    margin-top: 1em;
    font-weight: 400;
    font-family: 'Roboto Mono', sans-serif;
}

a {
    color: #f0f0f0!important;
    text-decoration: underline;
    font-weight: 700;
}

a:hover {
    color: #f0f0f0;
    text-decoration: underline;
}

h1 {
    color: #f0f0f0!important;
    font-weight: 900!important;
    font-size: 4em!important;
    text-transform: uppercase;
    margin: 8px 0px 8px 0px!important;
    font-family: 'Roboto', sans-serif;
}


h2 {
    color: #f0f0f0;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 2.125em;
    font-family: 'Roboto', sans-serif;

}

h3 {
    color: #f0f0f0;
    font-family: 'Roboto Mono', sans-serif;
    text-transform: uppercase;
    font-weight: 00;
    font-size: 1.5em;
    font-family: 'Roboto', sans-serif;
}

h4 {
    color: #f0f0f0;
    font-family: 'Roboto Mono', sans-serif;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 2em;
    font-family: 'Roboto Mono', sans-serif;

}

h5 {
    color: #f0f0f0!important;
    font-family: 'Roboto Mono', sans-serif;
    font-weight: 400!important;
    font-size: 1.25em!important;
}


hr {
    display: block;
    height: 2px;
    border: 0;
    border-top: 2px solid #f0f0f0 !important;
    margin: 1em 0;
    padding: 0;
    opacity: 100%!important;
}